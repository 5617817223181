import { useEffect, useState } from 'react';
import { GameDTO } from '../../shared/interfaces/game/Game.DTO';
import api from '../../shared/services/Api';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';

export default function Game() {
  const [game, setGame] = useState<GameDTO | null>(null);
  const { gameUrl } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/api/GamesRequest/GetGameByUrl?url=${gameUrl}`);
      setGame(response.data);
    };
    fetchData();
  }, []);

  if (!game) {
    return <>no data</>;
  }

  const stripHtmlTags = (html: string) => {
    return html.replace(/<\/?[^>]+(>|$)/g, '');
  };

  // Генерация мета-описания: ограничение до 150 символов, обрезка слов и добавление многоточия, если текст был обрезан.
  const truncateDescription = (description: string, maxLength: number) => {
    const strippedDescription = stripHtmlTags(description);
    if (strippedDescription.length <= maxLength) {
      return strippedDescription;
    }

    // Обрезаем строку до максимально допустимой длины
    const truncated = strippedDescription.substring(0, maxLength);

    // Находим последнее пространство, чтобы избежать обрыва слова
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    // Если пробел найден, обрезаем строку до этого места
    const finalDescription = lastSpaceIndex > 0 ? truncated.substring(0, lastSpaceIndex) : truncated;

    return finalDescription + '...';
  };

  const metaDescription = truncateDescription(game.description, 150);

  return (
    <>
      <Helmet>
        <title>{game.name}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <div
        className="p-3 bg-light text-dark"
        style={{
          width: '1000px',
        }}
      >
        <h1>{game.name}</h1>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(game.description) }}></div>
      </div>
    </>
  );
}
