import { Helmet } from 'react-helmet-async';

export default function HomePage() {
  return (
    <>
      <Helmet>
        <title>{'Cyber gaming club'}</title>
        <meta name="description" content={''} />
      </Helmet>
      <div>...</div>
    </>
  );
}
