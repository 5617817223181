import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/Home.Page';

export default function HomeNonAuthRoutes() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />}></Route>
    </Routes>
  );
}
