import { useEffect, useState } from 'react';
import { GameDTO } from '../../shared/interfaces/game/Game.DTO';
import api from '../../shared/services/Api';
import { Link, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { PaginatedListDTO } from '../../shared/interfaces/common/PaginatedList.DTO';
import { Helmet } from 'react-helmet-async';

export default function GameList() {
  const [games, setGames] = useState<PaginatedListDTO<GameDTO> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const pageIndex = parseInt(searchParams.get('pageIndex') || '1', 10);
  const pageSize = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);
        const response: AxiosResponse<PaginatedListDTO<GameDTO>> = await api.get(`/api/GamesRequest/GetGames?pageIndex=${pageIndex}&pageSize=${pageSize}`);
        if (response.status === 200) {
          setGames(response.data);
        } else {
          setError(`Unexpected response code: ${response.status}`);
        }
      } catch (err) {
        if (err instanceof Error) {
          setError(`Failed to fetch games: ${err.message}`);
        } else {
          setError('An unknown error occurred');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageIndex]);

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <>{error}</>;
  }

  if (!games?.items || games.items.length === 0) {
    return <>No games available</>;
  }

  const handlePageChange = (newPageIndex: number) => {
    setSearchParams({ pageIndex: newPageIndex.toString() });
  };

  return (
    <>
      <Helmet>
        <title>{'Game list'}</title>
        <meta name="description" content={''} />
      </Helmet>
      {games.items.map((game) => (
        <div key={game.id}>
          <Link to={`/games/view/${game.url}`}>{game.name}</Link>
        </div>
      ))}
      <div className="m-3 pb-5">
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className={`page-item ${pageIndex === 1 ? 'disabled' : ''}`}>
              <button className="page-link" aria-label="Previous" onClick={() => handlePageChange(pageIndex - 1)} disabled={pageIndex === 1}>
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>
            {[...Array(games.totalPages)].map((_, index) => (
              <li key={index + 1} className={`page-item ${pageIndex === index + 1 ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                  {index + 1}
                </button>
              </li>
            ))}
            <li className={`page-item ${pageIndex >= games.totalPages ? 'disabled' : ''}`}>
              <button className="page-link" aria-label="Next" onClick={() => handlePageChange(pageIndex + 1)} disabled={pageIndex >= games.totalPages}>
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
